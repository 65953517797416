import React, { Fragment } from 'react'
import { TopNav } from './components/TopNav'
import { Banner } from './components/Banner'
import { Abo } from './components/Abo'
import { Service } from './components/Service'

//@ts-ignore
import ImageMasonry from 'react-image-masonry'
import { Footer } from './components/Footer'

import firebase from 'firebase'
import IClient from './constants/interfaces/IClient'
import moment from 'moment'

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
}

firebase.initializeApp(config)

interface IAppState {
	readonly loading: boolean
	readonly whiteMenuIsShown: boolean
	readonly menuWillDisappear: boolean
}

export default class App extends React.Component {
	public state: IAppState = {
		loading: true,
		whiteMenuIsShown: false,
		menuWillDisappear: false
	}

	public componentDidMount () {
		setTimeout(() => {
			this.setState({ loading: false })
		}, 250)
	}

	private _handleMenu = (shouldOpen: boolean) => {
		if (shouldOpen) {
			this.setState({ whiteMenuIsShown: shouldOpen })
		}
		else {
			if (this.state.whiteMenuIsShown) {
				this.setState({ menuWillDisappear: true }, () => {
					setTimeout(() => {
						this.setState({ whiteMenuIsShown: shouldOpen, menuWillDisappear: false })
					}, 300)
				})
			}
		}
	}

	private _handleSubmit = async (customer: IClient): Promise<any> => {
		try {
			const doc = await firebase.firestore().collection('customers').where('email', '==', customer.email).get()
			if (!!doc.empty) {
				try {
					await firebase.firestore().collection('customers').add(customer)
					return { done: true }
				} catch (error) {
					console.log(error)
					return { done: false, error: 'Courriel existant. Venez nous voir!' }
				}
			}
			return { done: false, error: 'Courriel existant. Venez nous voir!' }
		} catch (error) {
			console.log(error)
			return { done: false, error: 'Courriel existant. Venez nous voir!' }
		}
	}

  public isPromo() {
    return moment().isSameOrAfter(moment('2023-11-24')) && moment().isSameOrBefore(moment('2023-11-27'))
  }

	public render () {
		return (
			<div id='App' className={!this.state.loading ? 'ready' : ''}>
				{this.state.whiteMenuIsShown && <div style={{ height: 98 }} />}
				<TopNav
					handleMenu={this._handleMenu}
					isSticky={this.state.whiteMenuIsShown}
					shouldDisappear={this.state.menuWillDisappear}
				/>
				<div style={{ backgroundColor: '#fff', zIndex: -1, overflow: 'hidden' }}>
					<Banner />
				</div>

				<div className='abo'>
					<div style={{ maxWidth: 1200, margin: 'auto', padding: '12px 6px 24px 6px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {this.isPromo() && <Fragment>
              <h2 style={{fontSize:'32pt'}}>
                Offre spéciale du BLACK FRIDAY
              </h2>
              <a className='btn' style={{  border: '2px solid black', boxShadow:'2px 1px 10px rgba(0,0,0,0.8)',  marginBottom: 32, fontSize: '24pt', borderRadius: '24pt', zIndex:9, padding: '20px 14px' }} target='_blank' href='https://privilegegym.fliipapp.com/user/register/buy_membership/1/19111'>
              OBTENIR 3 MOIS 
              </a>
            </Fragment>}
						<h3 style={this.isPromo() ? {borderTop:'1px solid black', paddingTop: 32}:{}}>
							Avec plus de <span>35 ans d'expérience,</span> nous avons mis au point un gym qui optimise
							les résultats. Nous fournissons le savoir et l'équipement, vous contribuez avec votre
							énergie et vos réussites!
						</h3>
					</div>
				</div>
				<div>
					<div style={{ maxWidth: 1200, margin: '24px auto', padding: '12px 6px 24px 6px' }}>
						<h4 style={{ color: '#fff' }}>L'essayer c'est l'adopter!</h4>
						<p style={{ color: '#fff' }}>
							Laissez notre gym parler de lui-même, un essai vous convraincra. Sur place, notre
							chaleureuse équipe vous fera un tour guidé de nos quatre étages ainsi que de notre terrasse
							(en saison), un concept unique à Québec.
						</p>
						<a className='btn' target='_blank' href='https://m.me/privilegegym?ref=essai_gratuit'>
							Obtenir mon essai gratuit
						</a>
					</div>
				</div>
				<Abo onSubmit={this._handleSubmit} />
				<Service />
				<div id='privilege' style={{ marginTop: -28 }}>
					<ImageMasonry
						imageUrls={[
							'https://i0.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/image1-min.jpg?zoom=2&resize=249%2C200',
							'https://i2.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/AQP_6456-min.jpg?zoom=2&resize=249%2C200',
							'https://i1.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/AQP_6469-min.jpg?zoom=2&resize=249%2C200',
							'https://i0.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-21-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i1.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-20-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i2.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-16-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i0.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-15-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i1.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-7-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i0.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-3-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i1.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-19-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i0.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-10-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i2.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-5-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i0.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-4-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i2.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-2-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200',
							'https://i2.wp.com/www.privilegegym.com/wp-content/uploads/2015/03/IMAGE-1-DIAPO-2018-min.jpg?zoom=2&resize=249%2C200'
						]}
						numCols={3}
					/>
				</div>
				<Footer />
        
			</div>
		)
	}
}
