import React from 'react'
// import { Parallax } from 'react-scroll-parallax';

//@ts-ignore
import { Parallax } from 'react-parallax'

//@ts-ignore
import Bounce from 'react-reveal/Bounce'

export const Service: React.FC = (props) => {
	return (
		<div className='services'>
			<Parallax
				blur={0}
				bgImage={require('../assets/img/services-bg.jpg')}
				bgImageAlt='Privilège Gym - Services'
				strength={500}
				className='par-container'>
				<div className='container'>
					<div className='col'>
						<Bounce right cascade>
							<div>
								<h2>
									Votre santé nous tient à{' '}
									<span role='img' aria-label='<3'>
										❤️
									</span>
								</h2>
								<h3>Programme personnalisé</h3>
								<h3>Plan alimentaire</h3>
								<h3>Entraînement accompagné</h3>
								<h3>Clinique de soins esthétiques</h3>
							</div>
						</Bounce>
					</div>
				</div>
			</Parallax>
		</div>
	)
}
