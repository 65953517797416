import React from 'react'

interface ICardProps {
	readonly action: Function | string
	readonly info: string
	readonly buttonText: string
	readonly corner?: string
	readonly isPromo?: boolean
}

export const Card: React.FC<ICardProps> = (props) => (
	<div className='card'>
		{props.corner !== undefined && (
			<div className='corner'>
				<h3>{props.corner}</h3>
			</div>
		)}
		{props.isPromo === true && (
			<div className='promo'>
				<h3>promo</h3>
			</div>
		)}
		<ul>{props.children}</ul>
		{typeof props.action === 'string' ? (
			<a href={props.action} className='btn' target='_blank'>
				{props.buttonText}
			</a>
		) : (
			<button onClick={() => ((props as any) || { action: () => {} }).action()}>{props.buttonText}</button>
		)}
		<div className='info'>{props.info}</div>
	</div>
)
