import React from 'react'
import styled from 'styled-components'

const Col = styled.div``
const FooterDiv = styled.footer`padding: 50px 0px;`

const getIframeWidth = () => {
	if (window.innerWidth / 3 > 600) {
		return 600
	}

	return window.innerWidth / 3 - 100
}

const FbLastPostiFrame = styled.iframe`
	border: none;
	overflow: hidden;
	width: 100%;
	height: 600px;
	background: white;
	float: left;
	background-color: #2d2d2d;
`

const InstaiFrame = styled.iframe`
	border: none;
	overflow: hidden;
	width: 100%;
`

export const Footer: React.FC = () => {
	return (
		<FooterDiv>
			<Col className='col'>
				<p>Vous ne trouvez pas ce que vous cherchez?</p>
				<p style={{ fontSize: '10pt' }}>
					N'hésitez pas à communiquer avec nous. <br />L'un de nos représentants saura trouver la formule
					d'abonnement qui convient à vos besoins.
				</p>
				<h4>Privilège Gym</h4>
				<h5>Nous contacter:</h5>
				<a href='tel:4186640400'>
					<span role='img' aria-label='téléphone'>
						☎
					</span>{' '}
					(418) 664-0400
				</a>
				<br />
				<a href='mailto:privilege.gym@videotron.ca'>
					<span role='img' aria-label='courriel'>
						📨
					</span>{' '}
					privilege.gym@videotron.ca
				</a>
				<br />
				<p>Lundi au jeudi: 8h à 21h</p>
				<p>Vendredi: 8h à 20h</p>
				<p>Samedi et dimanche: 9h à 15h</p>
				<p style={{ display: 'flex', alignItems: 'center' }}>
					<span role='img' aria-label='courriel'>
						⚲
					</span>
					<address style={{ marginLeft: 6 }}>2510 Boulevard Louis XIV, Beauport, Québec</address>
				</p>
			</Col>
			<Col className='col'>
				<h4>Facebook</h4>
				<FbLastPostiFrame
					src={`http://www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2F167314966612724&colorscheme=light&show_faces=true&border_color&stream=true&header=true&height=600`}
				/>
			</Col>
			<Col className='col'>
				<h4>Instagram</h4>
				<InstaiFrame src='https://snapwidget.com/embed/723165' scrolling='no' className='snapwidget-widget' />
			</Col>
		</FooterDiv>
	)
}
