import React, { useState } from 'react'

import * as Scroll from 'react-scroll'
import { Element } from 'react-scroll'

import moment from 'moment'

import { Card } from './Card'
import IClient from '../constants/interfaces/IClient'

//@ts-ignore
import Bounce from 'react-reveal/Bounce'

var scroller = Scroll.animateScroll

const emptyCustomer = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  postal_code: '',
}

interface IAboProps {
  readonly onSubmit: (customer: IClient) => Promise<any>
}

export const Abo: React.FC<IAboProps> = (props) => {
  const fetchAboInUrl = (): string => {
    const pathname = window.location.pathname

    if (pathname.split('/')[pathname.split('/').length - 1]) {
      return pathname.split('/')[pathname.split('/').length - 1]
    }

    return ''
  }

  const [isSubscribing, setIsSubscribing] = useState(fetchAboInUrl() !== '')
  const [plan, setPlan] = useState(fetchAboInUrl())
  const [revision, setRevision] = useState(false)
  const [cardContainerHeight, setCardContainerHeight] = useState(0)
  const [customer, setCustomer] = useState(emptyCustomer)
  const [isSending, setIsSending] = useState(false)
  const [successSent, setSuccessSent] = useState(false)
  const [failedSent, setFailedSent] = useState(false)
  const [error, setError] = useState('')

  const getCardsContainerHeight = () => {
    const div = document.getElementById('cards-container') as HTMLDivElement

    if (div) {
      return div.clientHeight
    }

    return 0
  }

  const handlePlanSub = (plan: string) => {
    const div = document.getElementById('abonnements') as HTMLDivElement

    if (cardContainerHeight === 0) {
      setCardContainerHeight(getCardsContainerHeight())
    }

    scroller.scrollTo(div.offsetTop, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuint',
    })

    setPlan(plan)
    setIsSubscribing(true)
  }

  const getPlanName = () => {
    switch (plan) {
      case 'etudiant':
        return 'Forfait étudiant'
      case 'regulier':
        return 'Forfait régulier'
      default:
        return 'Forfait 24 heures'
    }
  }

  const cancelSub = () => {
    setIsSubscribing(false)
    setPlan('')
    setCustomer(emptyCustomer)
    setRevision(false)
    setFailedSent(false)
    setSuccessSent(false)
    setIsSending(false)
  }

  const formIsValid = () => {
    return (
      customer.first_name !== '' &&
      customer.last_name !== '' &&
      customer.address !== '' &&
      customer.city !== '' &&
      customer.email !== '' &&
      customer.email.indexOf('@') > -1 &&
      customer.email.indexOf('.') > -1 &&
      customer.phone !== '' &&
      customer.phone.length > 7 &&
      customer.postal_code !== '' &&
      customer.postal_code.replace(' ', '').length === 6
    )
  }

  const confirm = () => {
    setRevision(true)

    if (!formIsValid()) {
      const form = document.getElementById('form') as HTMLDivElement

      form.classList.add('animated')
      form.classList.add('shake')

      setTimeout(() => {
        form.classList.remove('animated')
        form.classList.remove('shake')
      }, 750)

      return
    }

    const customerForSubmit = {
      first_name: customer.first_name,
      last_name: customer.last_name,
      address: customer.address,
      city: customer.city,
      country: 'CA',
      province: 'Québec',
      phone_mobile: customer.phone,
      email: customer.email,
      postal_code: customer.postal_code,
      id:
        customer.first_name.slice(0, 3).toUpperCase() +
        customer.last_name.slice(0, 3).toUpperCase(),
      abonnements: [
        {
          id: 'nouvel_abonnement_website',
          begin_at: moment().toDate(),
          bought_at: moment().toDate(),
          end_at: moment().toDate(),
          service_id: plan,
          taxes_amount: [],
          total_price: 0,
        } as any,
      ],
      birthday: moment().toDate(),
      mobile_can_sms: true,
      phone_other: '',
    } as any

    setIsSending(true)

    props
      .onSubmit(customerForSubmit)
      .then((res) => {
        setIsSending(false)

        const div = document.getElementById('abonnements') as HTMLDivElement

        scroller.scrollTo(div.offsetTop, {
          duration: 500,
          delay: 0,
          smooth: 'easeInOutQuint',
        })

        if (res.done === true) {
          setSuccessSent(true)
          return
        }

        setFailedSent(true)
        setError(res.error)
      })
      .catch(() => {
        const div = document.getElementById('abonnements') as HTMLDivElement

        scroller.scrollTo(div.offsetTop, {
          duration: 500,
          delay: 0,
          smooth: 'easeInOutQuint',
        })

        setIsSending(false)
        setFailedSent(true)
        setError("Un catch n'est jamais bon signe...")
      })
  }

  const updateCustomer = (param: string, value: string) => {
    setCustomer({ ...customer, [param]: value })
  }

  const getCancelBtnText = () => {
    if (successSent || failedSent) {
      return 'Retour'
    }

    return 'Annuler'
  }

  return (
    <Element className='abo' id='abonnements' name='abonnements'>
      <div className='column' style={{ flexGrow: 1 }}>
        <div className='title' style={{ width: '100%' }}>
          <p>{!isSubscribing ? 'Tarifs annuels' : getPlanName()}</p>
          <h3
            className='strong'
            style={{
              marginTop: 0,
              ...(isSubscribing
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }
                : {}),
            }}
          >
            {!isSubscribing ? (
              'Abonnements'
            ) : (
              <span>
                Mon
                <br />
                Abonnement
              </span>
            )}
            {isSubscribing && (
              <span
                className='btn'
                style={{ marginTop: 10 }}
                onClick={() => cancelSub()}
              >
                {getCancelBtnText()}
              </span>
            )}
          </h3>
        </div>
        {!isSubscribing ? (
          <Bounce left>
            <div className='cards' id='cards-container'>
              {/* <Card
                action={
                  'https://privilegegym.fliipapp.com/user/register/buy_membership/1/19109'
                }
                corner='3 mois - Tarif étudiant 12 - 17 ans'
                info={`* taxes et puce d\'accès en sus. Preuve étudiante requise à la première visite.`}
                buttonText='M&#39;abonner'
              >
                <li>
                  <h4>
                    129.99$<span className='star'>*</span>
                  </h4>
                </li>
                <li>Accès 24 heures</li>
                <li>7 / 7 jours</li>
              </Card> */}
              {/* <Card
                action={
                  'https://privilegegym.fliipapp.com/user/register/buy_membership/1/19094'
                }
                corner='1 an - Tarif étudiant 12 - 17 ans'
                info={`* taxes et puce d\'accès en sus. Preuve étudiante requise à la première visite.`}
                buttonText='M&#39;abonner'
              >
                <li>
                  <h4>
                    29.99$<span className='star'>*</span>
                  </h4>
                  <p>Par mois</p>
                </li>
                <li>Accès 24 heures</li>
                <li>7 / 7 jours</li>
              </Card> */}
              <Card
                action={
                  'https://privilegegym.fliipapp.com/user/register/buy_membership/1/22105'
                }
                corner='1 an - Tarif étudiant 12 - 24 ans - Avec engagement'
                info={`* taxes et puce d\'accès en sus. Preuve étudiante requise à la première visite.`}
                buttonText='M&#39;abonner'
              >
                <li>
                  <h4>
                    39.99$<span className='star'>*</span>
                  </h4>
                  <p>Par mois</p>
                </li>
                <li>Accès 24 heures</li>
                <li>avec engagement</li>
                <li>7 / 7 jours</li>
              </Card>

              <Card
                action={
                  'https://privilegegym.fliipapp.com/user/register/buy_membership/1/19097'
                }
                info={`* taxes et puce d\'accès en sus`}
                buttonText='M&#39;abonner'
                corner='1 an - Avec engagement'
              >
                <li>
                  <h4>
                    49.99$<span className='star'>*</span>
                  </h4>
                  <p>Par mois</p>
                </li>
                <li>Accès 24 heures</li>
                <li>avec engagement</li>
                <li>7 / 7 jours</li>
              </Card>
              <div>
                <h3>Autres forfaits disponibles, renseignez-vous!</h3>
              </div>
            </div>
          </Bounce>
        ) : (
          <Bounce right>
            <div
              className='form-container'
              style={{
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p>
                Les résultats sont très proches! Passez dans votre gym après
                avoir complété le formulaire suivant pour finaliser votre
                inscription et récupérer votre puce d'accès.
              </p>
              {!successSent && !failedSent && (
                <div className='form' id='form'>
                  <div className='form-header'>
                    Ces informations nous permettront de commencer votre
                    abonnement.
                    <br />
                    L'abonnement commencera à votre prochaine visite.
                  </div>
                  <div className='form-body'>
                    <div className='form-row'>
                      <div className='group-col'>
                        <label htmlFor='first_name'>Votre prénom*:</label>
                        <input
                          className={`${
                            revision && customer.first_name === '' && 'invalid'
                          }`}
                          type='text'
                          id='first_name'
                          onChange={(e) =>
                            updateCustomer('first_name', e.currentTarget.value)
                          }
                          value={customer.first_name}
                        />
                      </div>
                      <div className='group-col'>
                        <label htmlFor='last_name'>Votre nom*:</label>
                        <input
                          className={`${
                            revision && customer.last_name === '' && 'invalid'
                          }`}
                          type='text'
                          id='last_name'
                          onChange={(e) =>
                            updateCustomer('last_name', e.currentTarget.value)
                          }
                          value={customer.last_name}
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='group-col'>
                        <label htmlFor='email'>Votre courriel*:</label>
                        <input
                          className={`${
                            revision &&
                            (customer.email === '' ||
                              (customer.email.indexOf('@') < 1 &&
                                customer.email.indexOf('.') < 0)) &&
                            'invalid'
                          }`}
                          type='email'
                          id='email'
                          onChange={(e) =>
                            updateCustomer('email', e.currentTarget.value)
                          }
                          value={customer.email}
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='group-col'>
                        <label htmlFor='phone'>Votre téléphone*:</label>
                        <input
                          className={`${
                            revision &&
                            (customer.phone === '' ||
                              customer.phone.length < 7) &&
                            'invalid'
                          }`}
                          type='phone'
                          id='phone'
                          onChange={(e) =>
                            !isNaN(Number(e.currentTarget.value)) &&
                            updateCustomer('phone', e.currentTarget.value)
                          }
                          value={customer.phone}
                          placeholder='4186640400'
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='group-col'>
                        <label htmlFor='address'>Votre adresse*:</label>
                        <input
                          className={`${
                            revision && customer.address === '' && 'invalid'
                          }`}
                          type='text'
                          id='address'
                          onChange={(e) =>
                            updateCustomer('address', e.currentTarget.value)
                          }
                          placeholder='235 rue du Progrès'
                          value={customer.address}
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='group-col'>
                        <label htmlFor='city'>Votre ville*:</label>
                        <input
                          className={`${
                            revision && customer.city === '' && 'invalid'
                          }`}
                          type='text'
                          id='city'
                          onChange={(e) =>
                            updateCustomer('city', e.currentTarget.value)
                          }
                          value={customer.city}
                        />
                      </div>
                      <div className='group-col'>
                        <label htmlFor='postal_code'>Votre code postal*:</label>
                        <input
                          className={`${
                            revision &&
                            (customer.postal_code === '' ||
                              customer.postal_code.replace(' ', '').length !==
                                6) &&
                            'invalid'
                          }`}
                          type='text'
                          id='postal_code'
                          onChange={(e) =>
                            e.currentTarget.value.replace(' ', '').length < 7 &&
                            updateCustomer(
                              'postal_code',
                              e.currentTarget.value.toUpperCase()
                            )
                          }
                          value={customer.postal_code}
                          placeholder='A0A 0A0'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-footer'>
                    {isSending ? (
                      <div className='spinner'>
                        <i />
                      </div>
                    ) : (
                      <span className='btn' onClick={() => confirm()}>
                        Confirmer
                      </span>
                    )}
                  </div>
                </div>
              )}
              {successSent && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span
                    className='success-bordered'
                    role='img'
                    aria-label='success'
                    style={{ fontSize: '50pt' }}
                  >
                    💪
                  </span>
                  <p>
                    Votre inscription a été envoyé avec succès! Vous recevrez un
                    message de confirmation sous peu.
                  </p>
                  <p>À très bientôt!</p>
                  <p>De l'équipe du Privilège Gym</p>
                </div>
              )}
              {failedSent && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span
                    className='failed-bordered'
                    role='img'
                    aria-label='success'
                    style={{ fontSize: '50pt' }}
                  >
                    🧐
                  </span>
                  <p>
                    Il s'est produite une erreur pendant la création de
                    l'abonnement.
                  </p>
                  <p>{error}</p>
                  <p>Désolé pour l'inconvénient, vous pouvez réessayer!</p>
                </div>
              )}
            </div>
          </Bounce>
        )}
        <div>
          <div
            style={{ display: 'block', maxWidth: 1200, margin: '24px auto' }}
          >
            <p>
              Nous rachetons également vos abonnements. Possibilité d'ajouter
              une assurance abonnement. Pour plus de détails,{' '}
              <a href='mailto:privilege.gym@videotron.ca'> communiquez avec nous</a>
            </p>
          </div>
        </div>
      </div>
    </Element>
  )
}
